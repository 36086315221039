import { NotifyService } from 'abp-ng2-module';
import { MainModuleStoreService } from './../../../main-module-store.service';
import { catchError, filter, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { HttpClient, HttpEventType, HttpParams, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppConsts } from '@shared/AppConsts';
import { EMPTY } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-csd-download-modal',
    templateUrl: './csd-download-modal.component.html',
    styleUrls: ['./csd-download-modal.component.scss'],
})
export class CsdDownloadModalComponent {
    @ViewChild('downloadModal', { static: true }) modal: ModalDirective;

    active = false;
    saving = false;
    /**
     * Whether the download is in progress or not
     */
    isBusy = false;

    availableInstallers: { label: string; value: string }[] = undefined;

    installerVersion = new FormControl(null, [Validators.required]);

    emptyValueLabelForSelect = 'Loading versions...';

    private readonly ENDPOINTS = {
        getInstaller: '/CytoSolverDesktop/GetInstaller',
        getVersions: '/CytoSolverDesktop/GetVersions',
    };

    constructor(
        private http: HttpClient,
        private fileDownloadService: FileDownloadService,
        private mainModuleStoreService: MainModuleStoreService,
        private notify: NotifyService
    ) {}

    show() {
        this.getDataToPopulateVersionsSelect();
        this.active = true;
        this.modal.show();
    }

    hide() {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }

        this.active = false;
        this.modal.hide();
        this.installerVersion.reset();
        this.availableInstallers = undefined;
        this.emptyValueLabelForSelect = 'Loading versions...';
    }

    triggerFileDownload() {
        this.isBusy = true;
        this.mainModuleStoreService.updateCsdInstallerDownloadProgress(0);

        this.http
            .get(AppConsts.remoteServiceBaseUrl + this.ENDPOINTS.getInstaller, {
                responseType: 'arraybuffer',
                observe: 'events',
                reportProgress: true,
                params: new HttpParams({
                    fromObject: {
                        name: this.installerVersion.value,
                    },
                }),
            })
            .pipe(
                catchError((e) => {
                    this.notify.error('Something went wrong with CSD installer downloading. Please try again later');

                    return EMPTY;
                }),
                tap((v: HttpResponse<ArrayBuffer> | HttpProgressEvent) => {
                    if (v?.type === HttpEventType.DownloadProgress) {
                        const progressInPercents = +((v.loaded / v.total) * 100).toFixed(0);

                        this.mainModuleStoreService.updateCsdInstallerDownloadProgress(progressInPercents);
                    }
                }),
                filter((response) => response instanceof HttpResponse),
                finalize(() => {
                    this.isBusy = false;
                    this.mainModuleStoreService.updateCsdInstallerDownloadProgress(null);
                })
            )
            .subscribe((data: HttpResponse<ArrayBuffer>) =>
                this.fileDownloadService.processToFileDownload(data, 'CSD_Installer.msi')
            );
    }

    private getDataToPopulateVersionsSelect() {
        this.http
            .get(AppConsts.remoteServiceBaseUrl + this.ENDPOINTS.getVersions)
            .pipe(
                take(1),
                takeUntil(this.modal.onHide),
                catchError(() => {
                    this.emptyValueLabelForSelect = 'Data not available';
                    this.installerVersion.clearValidators();
                    this.installerVersion.updateValueAndValidity();

                    return EMPTY;
                })
            )
            .subscribe(({ result }: { result: { displayName: string }[] }) => {
                this.availableInstallers = result.reverse().map((el, i) => {
                    this.emptyValueLabelForSelect = 'Choose from list';
                    return {
                        label: el.displayName + (i === 0 ? ' (latest)' : ''),
                        value: el.displayName,
                    };
                });

                this.installerVersion.setValue(this.availableInstallers[0].value);
            });
    }
}
