import {
    Component,
    ChangeDetectionStrategy,
    ViewChild,
    ViewEncapsulation,
    ChangeDetectorRef,
    Input,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
    selector: 'app-shared-modal',
    templateUrl: './shared-modal.component.html',
    styleUrls: ['./shared-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedModalComponent {
    @ViewChild('sharedModalComponent', { static: true }) modal: ModalDirective;
    @Input() modalCssClass = '';

    active = false;
    saving = false;

    constructor(private cdRef: ChangeDetectorRef) {}

    show() {
        this.active = true;
        this.modal.show();
        this.cdRef.detectChanges();
    }

    /**
     * Will hide the modal and will emit a single value after modal is hidden.
     */
    hide(): Observable<ModalDirective> {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }

        this.modal.hide();

        return this.modal.onHidden.pipe(
            take(1),
            tap(() => {
                this.active = false;
                this.cdRef.markForCheck();
            })
        );
    }
}
